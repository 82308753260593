import { Link } from 'gatsby-plugin-modal-routing-3';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './AboutUsButton.module.scss';

const AboutUsButton = ({ mobile }) => {
  return (
    <Link className={`${styles.root} ${mobile ? styles.mobile : styles.hideMobile}`} asModal to='/about'>
      more about us
    </Link>
  );
};

AboutUsButton.propTypes = {
  mobile: PropTypes.bool,
};

export default AboutUsButton;
