// extracted by mini-css-extract-plugin
export var slides = "Slideshow-module--slides--3-Hye";
export var slide = "Slideshow-module--slide--nbKTO";
export var button = "Slideshow-module--button--1Qd1Z";
export var previous = "Slideshow-module--previous--1YX7Y";
export var next = "Slideshow-module--next--3P4O6";
export var slideWrapper = "Slideshow-module--slide-wrapper--30-Fy";
export var slideWrapperActive = "Slideshow-module--slide-wrapper-active--2_2TX";
export var slideTextBlock = "Slideshow-module--slide-text-block--3XhYX";
export var slideTextBlockActive = "Slideshow-module--slide-text-block-active--3tD8h";
export var slideContent = "Slideshow-module--slide-content--1dGAO";
export var slideContentActive = "Slideshow-module--slide-content-active--1LswS";